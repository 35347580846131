.header
{
    border-bottom: 2px solid #4AA0D8;
    background-color: rgba(17, 165, 169, 0.5);
}

.header li a
{
    text-decoration: none;
    color:white;
}
 li a:hover
{
    text-decoration: none;
    color:yellow;
    font-weight: 700;
}
html {
    scroll-behavior: smooth;
   }
   
   a {
    text-decoration: none;
    color: black;
   } 
 .logo-img-top {
    height: 120px;
} 